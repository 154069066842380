import {
  getChain,
  getFiatFeeds,
  getLatestRouter,
  YODL_ROUTER_ABIS,
} from '@yodlpay/tokenlists';
import { useAccount } from 'wagmi';
import { useEffect, useMemo, useState } from 'react';
import { Address, Chain } from 'viem';
import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { SavedLink as SavedLinkSchema } from '@frontend/shared/validation';
import { chainById } from '@frontend/utils/chains';
import { SwapVenue } from '@frontend/shared/routefinder/venues';
import { CURRENCIES } from '@frontend/common';
import AcrossAbi from '@frontend/AbiWithAcross.json';
import { base, optimism, polygon } from 'viem/chains';

export const PRECISION = 10n ** 18n;

export function getRouterAbi(version: string) {
  return AcrossAbi;
  return YODL_ROUTER_ABIS[version];
}

export const getRouterAddress = (chainId: number) => {
  const router = getLatestRouter(chainId);
  return router?.address as Address;
};

// Lookup dexes configured in yodl-config and turn them into SwapVenues
export const swapVenuesOfChain = (chain: Chain) => {
  return getChain(chain.id).dexes as SwapVenue[];
};

export const useChainInfo = () => {
  const { chain } = useAccount();

  if (!chain) return { chain, chainInfo: undefined, router: undefined };

  const { chainInfo } = chainById(chain.id);
  const router = getLatestRouter(chain.id);

  return { chain, chainInfo, router };
};

export const useInvoiceCurrencies = () => {
  return CURRENCIES as string[];
};

export function useDebounce<T>(value: T, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setTimestamp(Date.now());
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { debouncedValue, timestamp };
}

export const useSavedLinks = () => {
  const [savedLinks, setSavedLinks] = useYodlStore((state) => [
    state.savedLinks,
    state.setSavedLinks,
  ]);

  useEffect(() => {
    const savedLinksString = localStorage.getItem('savedLinks');
    if (savedLinksString) {
      try {
        const savedLinksObj = JSON.parse(savedLinksString);
        SavedLinkSchema.parse(savedLinksObj);
        setSavedLinks(savedLinksObj);
      } catch (e) {
        console.error('Error: Unable to parse savedLinks');
      }
    }
  }, []);

  return savedLinks;
};

export const useSavedLink = () => {
  const savedLinks = useYodlStore((state) => state.savedLinks);
  const recipientIdentifier = useYodlStore((state) => state.idResolution);
  const setSavedLinks = useYodlStore((state) => state.setSavedLinks);

  useEffect(() => {
    const savedLinksString = localStorage.getItem('savedLinks');
    if (savedLinksString) {
      try {
        const savedLinksObj = JSON.parse(savedLinksString);
        SavedLinkSchema.parse(savedLinksObj);
        setSavedLinks(savedLinksObj);
      } catch (e) {
        console.error('Error: Unable to parse savedLinks');
      }
    }
  }, []);

  const savedLink = useMemo(() => {
    if (recipientIdentifier && savedLinks) {
      const link = savedLinks.find(
        (link) => link.identifier === recipientIdentifier.handle,
      );
      return link;
    }
    return null;
  }, [savedLinks, recipientIdentifier]);

  return savedLink;
};
