import { useDebounce } from '@frontend/client/hooks/index';
import { PREFERENCES_TEXT_KEY } from '@frontend/common';
import { YODL_ENS_DOMAIN } from '@frontend/common/constants';
import {
  useAddressEnsNames,
  useAddressSubnames,
  useAddSubname,
  usePrimaryName,
  useRecords,
  useUpdateSubname,
} from '@justaname.id/react';
import { useMutation } from '@tanstack/react-query';
import assert from 'assert';
import { useEffect, useMemo, useState } from 'react';
import { Address } from 'viem';

interface Props {
  addressOrEns?: string;
}

interface Preferences {
  chainIds: number[];
  tokenSymbols: string[];
}

interface SignPreferencesProps {
  username?: string;
  isRegistered?: boolean;
}

const useSignedPreferences = ({ addressOrEns }: Props) => {
  const { debouncedValue } = useDebounce(addressOrEns, 500);

  const { addressEnsNames, isAddressEnsNamesLoading } = useAddressEnsNames({
    address: debouncedValue as Address,
  });

  const {
    addressSubnames,
    isAddressSubnamesLoading,
    isAddressSubnamesFetching,
  } = useAddressSubnames({
    address: debouncedValue as Address,
  });

  const { primaryName, isPrimaryNameLoading, isPrimaryNameFetching } =
    usePrimaryName({
      address: debouncedValue as Address,
    });

  const preferencesFromSubname = useMemo(() => {
    const fromEns = addressEnsNames.find((name) => name.ens === primaryName);
    const fromSubname = addressSubnames.find(
      (subname) => subname.ens === primaryName,
    );

    return fromEns || fromSubname;
  }, [addressEnsNames, addressSubnames, primaryName]);

  const { records, isRecordsLoading, isRecordsFetching } = useRecords({
    ens: primaryName,
  });

  const parsePreferences = (preferences: string) => {
    const { chainIds = [], tokenSymbols = [] } = JSON.parse(preferences);

    return {
      chainIds,
      tokenSymbols,
    };
  };

  const preferencesFromSubnameRecord = useMemo(() => {
    // If we have preferences from a subname record, try to parse those first
    if (preferencesFromSubname) {
      const { records } = preferencesFromSubname;
      const preferencesText = records.texts.find(
        (text) => text.key === PREFERENCES_TEXT_KEY,
      );

      if (preferencesText?.value) {
        return parsePreferences(preferencesText.value);
      }
    }

    // Otherwise try to parse preferences from ENS text record
    if (records?.records.texts) {
      const ensText = records.records.texts.find(
        (text) => text.key === PREFERENCES_TEXT_KEY,
      )?.value;

      if (ensText) {
        return parsePreferences(ensText);
      }
    }

    return undefined;
  }, [preferencesFromSubname, records?.records.texts]);

  const isLoading = useMemo(
    () =>
      isAddressEnsNamesLoading ||
      isAddressSubnamesLoading ||
      isPrimaryNameLoading ||
      isRecordsLoading,
    [
      isAddressEnsNamesLoading,
      isAddressSubnamesLoading,
      isPrimaryNameLoading,
      isRecordsLoading,
    ],
  );

  const isFetching = useMemo(
    () =>
      isAddressSubnamesFetching || isPrimaryNameFetching || isRecordsFetching,
    [isAddressSubnamesFetching, isPrimaryNameFetching, isRecordsFetching],
  );

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    setIsFetched(!!debouncedValue && !isFetching);
  }, [debouncedValue, isFetching]);

  return {
    preferences: preferencesFromSubnameRecord,
    isLoading,
    isFetching,
    isFetched,
  };
};

const useSignPreferences = ({
  username,
  isRegistered,
}: SignPreferencesProps) => {
  const { addSubname } = useAddSubname();
  const { updateSubname } = useUpdateSubname();

  return useMutation({
    mutationFn: async ({ tokenSymbols, chainIds }: Preferences) => {
      assert(username, 'ENS is required');

      const preferences: Record<string, any> = { tokenSymbols };
      if (chainIds.length > 0) preferences.chainIds = chainIds;

      const text = { [PREFERENCES_TEXT_KEY]: JSON.stringify(preferences) };

      isRegistered
        ? await updateSubname({
            text,
            ens: username,
          })
        : await addSubname({
            text,
            username,
            ensDomain: YODL_ENS_DOMAIN,
          });
      window?.location.reload();
    },
  });
};

export { useSignedPreferences, useSignPreferences };
