'use client';

import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

export const isMobile = parser.getDevice().type === 'mobile';
export const isIos = parser.getOS().name === 'iOS';

export const isChrome =
  parser.getBrowser().name?.toLowerCase() === 'chrome' ||
  parser.getBrowser().name?.toLowerCase() === 'chromium';
export const isSafari = parser.getBrowser().name?.toLowerCase() === 'safari';
export const isFirefox = parser.getBrowser().name?.toLowerCase() === 'firefox';

export const isStandalone =
  typeof window !== 'undefined'
    ? window.matchMedia('(display-mode: standalone)').matches
    : false;
